<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="dialog = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn large color="white" :ripple="false" v-bind="attrs" v-on="on" text >Προσθήκη κάρτας</v-btn>
      </template>
      <v-card>
      <v-form ref="form"
        v-model="valid"
        lazy-validation>
        <h3 style="text-align: center; padding: 5%">Προσθήκη κάρτας</h3>
        <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Αριθμός κάρτας*"
                  v-model="card" 
                  :rules="cardRules"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Κωδικός κάρτας*"
                  type="text"
                  :rules="passwordRules"
                  v-model="password"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <small class="mt--20">*Υποχρεωτικά πεδία</small>
              </v-col>
              <v-col cols="12">
                <p class="error" v-if="errors">{{ errors }}</p>
              </v-col>
            </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="white darken-1"
            text
            @click="dialog = false"
          >
            Κλείσιμο
          </v-btn>
          <v-btn
            color="white darken-1"
            text
            @click="addLoyaltyCard"
          >
            Προσθήκη
          </v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import axios from 'axios'
    import { asyncLoading } from 'vuejs-loading-plugin'
    import { mapMutations, mapActions, mapGetters } from "vuex";

    export default {
       name: 'AddLoyaltyCard',
       data() {
           return  {
             errors: '',
               valid: true,
               dialog: false,
               card: '',
               password: '',
               cardRules: [
                v => !!v || 'Ο αριθμός κάρτας είναι υποχρεωτικός',
                v => /^\d+$/.test(v) || 'Ο αριθμός κάρτας πρέπει να περιέχει μόνο αριθμούς',
                v => (v && (""+v).length >= 5) || 'Ο αριθμός κάρτας πρέπει να αποτελείται απο περισσότερους απο 5 αριθμούς',
              ],
              passwordRules: [
                v => !!v || 'Ο κωδικός είναι υποχρεωτικός',
              ],
           }
       },
       methods: {
         ...mapActions(["insertLoyaltyCard"]),
           async addLoyaltyCard(e){
             try{
                if(this.$refs.form.validate()){
                      this.$loading(true)
                      e.preventDefault();
                      this.errors = '';
                        axios.post('external/me/loyalty-cards', 
                        {
                            card_number: this.card,
                            card_password: this.password,
                        }).then(response => {
                            this.dialog = false
                             this.insertLoyaltyCard(response.data.data)
                             this.$refs.form.reset();
                             this.$loading(false)
                             this.$swal({
                                title: "Συγχαρητήρια",
                                text: "Η κάρτα aegean έχει αποθηκευτεί επιτυχώς.",
                                icon: "success", //built in icons: success, warning, error, info
                                timer: 3000,
                                  buttons: {
                                    confirm: {
                                      text: "OK",
                                      value: true,
                                      visible: true,
                                      className: "",
                                      closeModal: true
                                    },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      className: "",
                                      closeModal: true,
                                    }
                                  }
                              });
                        }).catch(error => {
                          
                            this.$loading(false)
                            this.errors = "Παρακαλώ ελέγξτε τα στοιχεία σας.";
                        });
                  
                }else{
                  return false;
                }            
              }catch(e){
                  this.error = e.message;
                  return e;
              }
           }
       },
    };
</script>
