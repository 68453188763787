<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
        <v-col lg="6"
              md="6"
              sm="12"
              cols="12">
            <v-row align="center" justify="center" >
              <v-col align="center" justify="center" >
                <v-img data-aos="fade-left" data-aos-duration="1200" width="650" src="@/assets/images/blog/addcards.png" alt="Creative Agency" />
              </v-col>
            </v-row>
            <v-row align="center" justify="center" >
              <p>Κατέβασε το app</p><br>
            </v-row>
            <v-row align="center" justify="center" >
              <v-col align="right">
                <a href="https://apps.apple.com/us/app/id1489983713"  target="blank" class="app-btn1"><v-img width="100" src="@/assets/images/blog/appstore-tra-grey.png" alt=""/></a>
              </v-col>
              
              <v-col>
                <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba"  target="blank" class="app-btn2"><v-img width="100" src="@/assets/images/blog/googleplay-tra-grey.png" alt="" /></a>
              </v-col>
            </v-row>
        </v-col>
              
               <v-col lg="5"
                     md="5"
                     sm="12"
                     cols="12">

                <div class="single-service text-white mb--50 mt--50 container">
                  <button class="btn btn-default" data-toggle="modal" data-target="#Addcard"><AddLoyaltyCard title="Προσθήκη κάρτας" icon="mdi-plus" />
                  </button>
                  <div v-if="loyaltyCards.loyalty_cards.length == 0">
                      Δεν υπάρχουν δεδομένα
                    </div>
                  <div v-else class="single-service service__style--3 text-white mb--50">
                          
                          <div id="loyaltyCardsList" v-for="(loyaltyCard, i) in loyaltyCards.loyalty_cards"
                              :key="i">
                              <v-row>
                              <v-col lg="2" md="2"  sm="12" cols="12" ></v-col>
                                  <v-col lg="8" md="8"  sm="12" cols="12" >
                                    <div class="pt--20 mb--50" style='border: 1px solid lightgrey; width:100%;border-radius: 25px;'>
                                        <img src="@/assets/images/blog/priviledge_card.png" width="250" alt="Creative Agency" />
                                        <h2 class="text-center"><p>{{ loyaltyCard.card_number }}</p></h2>
                                        <h2 class="text-center"><p>XXX</p></h2>
                                    </div>
                                  </v-col>
                                 
                               </v-row>
                          </div>
                      
                    </div>
                  </div>
              </v-col>
                           
          </v-row>
          <b-pagination
            class="mt--100"
            v-if="this.loyaltyCards.pagination.total_pages > 1"
            v-model="currentPage"
            :total-rows="rows"
            v-on:click.native="scrollToTop"
            :per-page="this.loyaltyCards.pagination.items_per_page"
            aria-controls="loyaltyCardsList"
            align="center"
          ></b-pagination>
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>
<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import AddLoyaltyCard from "@/views/AddLoyaltyCard";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import { asyncLoading } from 'vuejs-loading-plugin'

  export default {
    components: {
      Header,
      Footer,
      AddLoyaltyCard,
    },
    data() {
      return {
         loading: true,
         fields: [
          // A column that needs custom formatting
          { key: 'image', label: '' }, 
          { key: 'cardNumber', label: 'Αριθμός Κάρτας' }, 
          { key: 'cardCode', label: 'Κωδικός' }, 
        ],
        currentPage: 1,
        loading: true,
      }
    },
    watch: {
      currentPage: async function (newQuestion, oldQuestion) {
        await this.getLoyaltyCards(newQuestion);
      }
    },
    computed: {
       ...mapGetters (["loyaltyCards"]),
       ...mapGetters (["user"]),
      permission() {
        return this.$store.state.user;
      },
      rows() {
        return this.loyaltyCards.length
      }

    },
    async  created () {
      if(!this.user){
        this.$loading(true);
        await this.getUser();
        this.$loading(false);
        if(!this.user){
         this.$router.push('/')
         return
        }
      }
      if(!this.loyaltyCards){
        this.$loading(true);
        await this.getLoyaltyCards(1);
        this.$loading(false);
      }else{
        await this.getLoyaltyCards(1);
      }
    },
    methods: {
      scrollToTop() {
        this.$vuetify.goTo(0);
      },
      ...mapActions(["getLoyaltyCards"]),
      ...mapActions(["getUser"]),
    },
  };
</script>

<style scoped>

img {
    display: block;
    margin: auto;
    transition: all 10s ease;
}

</style>